import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFDatePicker from "@/components/Input/DatePicker";
import CardsList from "@/components/CardsList";

import {PlayerAPI} from "@/api/player";
import {StatAPI} from "@/api/stat";

import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const DEFAULT_CATEGORY = "Under 19";

const StatsContainer = (props) => {
	const {type} = props;

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
		filtered: true,
	});

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});

	const [category, setCategory] = useState();
	const [categoryPlayers, setCategoryPlayers] = useState([]);

	const [stats, setStats] = useState();
	const [statsDate, setStatsDate] = useState(new Date());

	useEffect(() => {
		if (categories?.length) {
			setCategory(categories.filter((c) => c.label === DEFAULT_CATEGORY)?.[0]);
		}
	}, [categories]);

	const getRPEStats = async () => {
		const selectedDate = moment(statsDate);
		// const currentWeek = selectedDate.week();
		const fromDate = selectedDate.startOf("isoWeek").format("YYYY-MM-DD");
		const toDate = selectedDate.endOf("isoWeek").format("YYYY-MM-DD");

		let promises = [];
		categoryPlayers.forEach((cp) => {
			promises.push(
				// StatAPI.trainingsDetails(cp.id, {
				// 	playerId: cp.id,
				// 	yearWeek:
				// 		moment(statsDate).year() +
				// 		"" +
				// 		(currentWeek < 10 ? "0" + currentWeek : currentWeek),
				// }),
				PlayerAPI.listRPE({
					playerId: cp.id,
					fromDate,
					toDate,
				}),
			);
		});
		try {
			let responses = await Promise.all(promises);
			responses = responses.map((r, k) => {
				const totalBox = {
					eventDuration: 0,
					avgRpe: 0,
					trainingLoad: 0,
				};

				let totalRpe = 0;
				r.forEach((rd) => {
					totalRpe += parseInt(rd.rpe);
					totalBox.eventDuration += rd.eventDuration;
					totalBox.trainingLoad += rd.trainingLoad;
				});
				totalBox.avgRpe = Math.round((totalRpe / r.length) * 100) / 100;

				return {
					player: categoryPlayers[k],
					items: [...r, totalBox],
				};
			});
			setStats(responses);
		} catch (error) {
			console.log(error);
		}
	};

	const getTQRStats = async () => {
		const fromDate = moment(statsDate).startOf("week").format("YYYY-MM-DD");
		const toDate = moment(statsDate).endOf("week").format("YYYY-MM-DD");

		let promises = [];
		categoryPlayers.forEach((cp) => {
			promises.push(PlayerAPI.playerListTQR(cp.id, {fromDate, toDate}));
		});
		try {
			let responses = await Promise.all(promises);
			responses = responses.map((r, k) => {
				return {
					player: categoryPlayers[k],
					items: r,
				};
			});
			setStats(responses);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (categoryPlayers?.length) {
			switch (type) {
				case "rpe":
					getRPEStats();
					break;
				case "tqr":
					getTQRStats();
					break;
				default:
					break;
			}
		}
	}, [type, categoryPlayers, statsDate]);

	useEffect(() => {
		if (category && players?.length) {
			setCategoryPlayers(
				players.filter(
					(p) => p.category === category?.label && p.status === "Interno",
				),
			);
		} else {
			setCategoryPlayers([]);
		}
	}, [category, players]);

	const getListConfiguration = (item) => {
		switch (type) {
			case "rpe":
				return {
					data: item.items?.filter((i) => i.avgRpe || i.rpe > 0),
					customClassName: (item) => (item.avgRpe ? "total" : ""),
				};
			case "tqr":
				return {
					data: item.items?.filter((i) => parseInt(i.tqrValue) > 0),
				};
			default:
				return {};
		}
	};

	const getCardsList = (stat) => {
		let list = <></>;
		switch (type) {
			case "rpe":
				list = (
					<CardsList
						type="rpe_stat"
						listConfiguration={getListConfiguration(stat)}
						defaultSortedField="trainingDate"
						defaultSortedOrder="ascend"
					/>
				);
				break;
			case "tqr":
				list = (
					<CardsList
						type="tqr_stat"
						listConfiguration={getListConfiguration(stat)}
						defaultSortedField="tqrDate"
						defaultSortedOrder="ascend"
					/>
				);
				break;
			default:
				break;
		}

		return list;
	};

	// eventDuration: 150
	// matchId: null
	// rpe: "5"
	// rpeDate: "2025-01-28T14:45:00+01:00"
	// trainingId: "54899ba5-c5e1-4e2d-9c73-0125c29963d4"
	// trainingLoad: 750

	return (
		<div className="sheet-container measurements-container">
			<div className="row mt-2">
				<div className="col-12">
					<VLFCard
						title="Filtri"
						content={
							<div className="row no-margin">
								<div className="col-12 col-md-4">
									<VLFSelectNew
										options={categories}
										sortOrder="descend"
										value={category}
										onChange={(newValue) => {
											setCategory(newValue);
										}}
										label="Categoria"
										required
									/>
								</div>
								<div className="col-12 col-md-4">
									<VLFDatePicker
										type="week"
										label="Settimana"
										customDateFormat="dd/MM/yyyy"
										value={
											new Date(moment(statsDate).startOf("week").isoWeekday(1))
										}
										onChange={setStatsDate}
										required
									/>
								</div>
							</div>
						}
					/>
				</div>
			</div>
			<div className="row players-stats mt-2">
				{stats
					?.sort((a, b) => {
						if (a.player.lastName < b.player.lastName) {
							return -1;
						}
						if (a.player.lastName > b.player.lastName) {
							return 1;
						}
						return 0;
					})
					?.map((s, k) => {
						return (
							<div className="col-12" key={k}>
								<div className="single-player-stat">
									<div className="player-name">
										{s.player?.firstName + " " + s.player?.lastName}
									</div>
									{getCardsList(s)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default StatsContainer;
