import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {PlayerAPI} from "@/api/player";

import AddIcon from "@mui/icons-material/Add";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

import {ERROR_OPERATION} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

function PlayersPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const user = useSelector((state) => state?.user);

	const [loading, setLoading] = useState(false);
	const [players, setPlayers] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("player_filters")) || {},
	);
	const [playerType, setPlayerType] = useState("Interno");

	const {categories} = useCategories({setLoading: false, handleNotification});

	const filters = [
		{
			key: "search",
			label: "Portiere",
			value: filtersData?.search,
			setValue: (value) => {
				sessionStorage.setItem(
					"player_filters",
					JSON.stringify({search: value}),
				);
				setFiltersData({...filtersData, search: value});
			},
		},
	];

	const getPlayers = async () => {
		if (!filtersData) {
			setLoading(true);
		}
		try {
			const response = await PlayerAPI.list(filtersData);
			setPlayers(response.filter((p) => p.goalkeeper));
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlayers();
	}, [filtersData]);

	const groupedPlayers = {};
	categories
		.filter((c) => c.name !== "Prima Squadra")
		.filter((c) => {
			if (user?.userName === "CaputoD") {
				return parseInt(c?.name?.split(" ")?.[1]) <= 13;
			} else {
				return true;
			}
		})
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const playersMembers = players?.filter(
				(p) =>
					p.category === currentCategory &&
					(!playerType || playerType === p.status),
			);
			groupedPlayers[currentCategory] = playersMembers;
		});

	const playersListConfiguration = {
		data: groupedPlayers,
		onClick: (player) => navigate("/players/" + player.id),
		imageUrl: () => require("@/assets/images/manager/player.png"),
		// icon: (player) =>
		// 	[].indexOf(player.id) !== -1 && <LocalHospitalIcon />,
		status: (player) =>
			user?.roleName === "Admin"
				? player.semaphore === 1
					? "top"
					: player.semaphore === 2
						? "middle"
						: player.semaphore === 3
							? "bottom"
							: ""
				: "",
		title: (player) => player.firstName + " " + player.lastName,
		subtitle: (player) =>
			player.dateOfBirth ? moment(player.dateOfBirth).format("YYYY") : "---",
		separator: false,
	};

	const mainActions =
		user?.userName === "CaputoD"
			? []
			: [
					{
						icon: <AddIcon />,
						title: "Aggiungi portiere",
						onClick: () => navigate("/players/new"),
					},
				];

	const listElements = [
		{
			label: "Rosa attuale",
			value: "Interno",
			onClick: () => setPlayerType("Interno"),
		},
		{
			label: "In prestito",
			value: "Prestito",
			onClick: () => setPlayerType("Prestito"),
		},
		{
			label: "Ceduti",
			value: "Esterno",
			onClick: () => setPlayerType("Esterno"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				currentListElement={playerType}
				listElements={listElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<CardsList
				breadcrumbs
				grouped
				filtersData={filters}
				type="players"
				listConfiguration={playersListConfiguration}
				defaultSortedField="category"
				defaultSortedOrder="descend"
			/>
		</Page>
	);
}

export default PlayersPage;
