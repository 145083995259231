import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function RPEStatCard(props) {
	const {element} = props;

	const isTotalElement = element.avgRpe;

	let tlStatusClassName = "";
	if (element.trainingLoad <= 300) {
		tlStatusClassName = "light";
	} else if (element.trainingLoad > 300 && element.trainingLoad <= 700) {
		tlStatusClassName = "medium";
	} else if (element.trainingLoad > 700) {
		tlStatusClassName = "strong";
	}

	let totalElementCardBody = (
		<div className={"rpe-stat-card-info"}>
			<div className="info-title">
				<div className="rpe-stat-info">
					{+parseFloat(element.avgRpe).toFixed(2)}
				</div>
				<div className="rpe-stat-sub-info">{"Media RPE"}</div>
			</div>
			<div className="separator vertical"></div>
			<div className="info-title">
				<div className="rpe-stat-info">{element.trainingLoad}</div>
				<div className="rpe-stat-sub-info">{"Training load"}</div>
			</div>
		</div>
	);

	let imagePath = "";
	if (element.trainingId) {
		imagePath = require("@/assets/images/coach/training.png");
	} else {
		imagePath = require("@/assets/images/coach/match.png");
	}

	return (
		<div
			className={"rpe-stat-card-container" + (isTotalElement ? " total" : "")}
		>
			<div className="rpe-stat-card-header">
				{!isTotalElement && (
					<div className="top-left">
						{moment(element.rpeDate).format(dateFormat)}
					</div>
				)}
				<div className="top-right">
					{element.rpe || element.avgRpe
						? "Durata " + element.eventDuration + "'"
						: ""}
				</div>
			</div>
			<div className="separator"></div>
			{isTotalElement ? (
				totalElementCardBody
			) : (
				<div className="rpe-stat-card-info flex-row">
					<div className="stat-img-container" style={{flex: 1}}>
						<img src={imagePath} alt="" />
					</div>
					<div className="separator vertical"></div>

					<div
						style={{
							flex: 3,
							display: "flex",
							flexDirection: "column",
							gap: "5px",
						}}
					>
						<div className="info-title">
							<div className="rpe-stat-info">{element.rpe}</div>
							<div className="rpe-stat-sub-info">{"RPE"}</div>
						</div>
						<div className={"info-subtitle " + tlStatusClassName}>
							{"Training load: " + element.trainingLoad}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
