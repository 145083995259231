import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import MainActions from "@/components/MainActions";
import Breadcrumbs from "@/components/Breadcrumbs";
import Tabs from "@/components/Tabs";
import VLFCard from "@/components/Card";
import EmptyData from "@/components/EmptyData";
import ViewField from "@/components/Input/ViewField";
import VLFDatePicker from "@/components/Input/DatePicker";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";
import ChartContainer from "@/components/ChartContainer";
import PlayerRadarChart from "@/components/PlayerRadarChart";
import VLFModal from "@/components/Modal";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import HandlePhysicHeightInfo from "@/pages/Player/Modals/handlePhysicHeightInfo";
import HandlePhysicWeightInfo from "@/pages/Player/Modals/handlePhysicWeightInfo";
import HandleContactModal from "@/pages/Player/Modals/handleContact";
import HandleMovementModal from "@/pages/Player/Modals/handleMovement";
import ValuationsPage from "@/pages/Valuations";
import NotesSection from "@/components/NotesSection";
import MedicalRecordPage from "@/pages/Medical/Record";
import MatchReportsPage from "@/pages/MatchReports";
import GPSContainer from "@/components/GPSContainer";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {PlayerAPI} from "@/api/player";
import {StatAPI} from "@/api/stat";
import {ValuationAPI} from "@/api/valuation";
import {AttachmentAPI} from "@/api/attachment";

import {setPlayerTab} from "@/actions";

import {
	dateFormat,
	playerSemaphoreOptions,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";
import {isCompetitiveActivity} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";
import {GpsAPI} from "../../api/gps";

const MATCH_STATS = {
	goalsConceded: "Goal subiti",
	playedMinutes: "Minuti",
	matches: "Partite",
};
const matchTypes = ["Amichevole", "Campionato", "Torneo"];

function PlayerPage(props) {
	const {handleNotification} = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {id} = useParams();

	const seasonId = useSelector((state) => state?.season?.id);
	const user = useSelector((state) => state?.user);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [playerData, setPlayerData] = useState({});

	const [openPhysicInfoWeight, setOpenPhysicInfoWeight] = useState(false);
	const [openPhysicHeightInfo, setOpenPhysicHeightInfo] = useState(false);
	const [openContact, setOpenContact] = useState(false);
	const [openMovement, setOpenMovement] = useState(false);
	const [openHandleAttachmentConfirm, setOpenHandleAttachmentConfirm] =
		useState(false);

	const [initialData, setInitialData] = useState();
	const [idAttachment, setIdAttachment] = useState();
	const [attachments, setAttachments] = useState([]);

	const [valuations, setValuations] = useState([]);
	const [valuationsData, setValuationsData] = useState([]);
	const [playerNotes, setPlayerNotes] = useState([]);
	const [playerMovements, setPlayerMovements] = useState([]);
	const [playerGPSData, setPlayerGPSData] = useState({});

	const checkMovement = (playerMovements, m, i) => {
		if (!m.prospectTeam) {
			for (let i2 = i - 1; i2 >= 0; i2--) {
				if (playerMovements[i2]?.prospectTeam) {
					m.prospectTeam = playerMovements[i2]?.prospectTeam;
					break;
				}
			}
		}

		if (!m.membershipDate) {
			for (let i2 = i - 1; i2 >= 0; i2--) {
				if (playerMovements[i2]?.membershipDate) {
					m.membershipDate = playerMovements[i2]?.membershipDate;
					break;
				}
			}
		}

		if (!m.movementType?.status) {
			for (let i2 = i - 1; i2 >= -1; i2--) {
				if (playerMovements[i2]?.movementType?.status) {
					m.movementType.status = playerMovements[i2]?.movementType?.status;
					break;
				}
			}
		}

		return m;
	};

	const getPlayer = async () => {
		setLoading(true);
		try {
			const response = await PlayerAPI.getById(id);
			response.hasStats = isCompetitiveActivity(
				categories,
				response.playerCategoryName,
			);
			setPlayerData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const getPlayerNotes = async () => {
		try {
			const response = await PlayerAPI.getPlayerNotes(id);
			setPlayerNotes(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getPlayerMovements = async () => {
		try {
			let response = await PlayerAPI.getPlayerMovements(id);
			response = response.map((movement, index) => {
				return checkMovement(response, movement, index);
			});
			setPlayerMovements(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getPlayerAttachment = async () => {
		try {
			const response = await AttachmentAPI.list("player", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getValuations = async () => {
		try {
			const response = await ValuationAPI.playerList(id);
			setValuations(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getPlayerGPS = async () => {
		try {
			const response = await GpsAPI.playerList(id);
			const GPSData = {};
			response.forEach((g) => {
				const currentMatchId = g.matchId;
				if (!GPSData[currentMatchId]) {
					GPSData[currentMatchId] = [];
				}

				GPSData[currentMatchId].push(g);
			});

			Object.keys(GPSData).forEach((key) => {
				GPSData[key].sort((a, b) => {
					const dateA = new Date(a.match.startDate); // Campo startDate nel primo elemento
					const dateB = new Date(b.match.startDate);
					return dateA - dateB; // Ordina in ordine crescente
				});
			});

			setPlayerGPSData(GPSData);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		if (categories?.length) {
			getPlayer();
			getPlayerMovements();
			getPlayerNotes();
			getPlayerAttachment();
			getValuations();
			getPlayerGPS();
		}
	}, [categories]);

	useEffect(() => {
		if (valuations?.length) {
			const promises = valuations.map((v) => {
				return ValuationAPI.getById(v.id);
			});

			Promise.all(promises).then((responses) => {
				setValuationsData(responses);
			});
		}
	}, [valuations]);

	const deletePlayer = async () => {
		setLoading(true);
		try {
			await PlayerAPI.delete(playerData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/players");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};
	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(idAttachment);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleAttachmentConfirm(false);
			getPlayerAttachment();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {
				responseType: "blob",
			});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const mainConfiguration = [
		{
			value: playerData?.firstName,
			label: "Nome",
			size: "col-6",
		},
		{
			value: playerData?.lastName,
			label: "Cognome",
			size: "col-6",
		},
		{
			value: playerData?.dateOfBirth
				? moment(playerData?.dateOfBirth).format(dateFormat)
				: null,
			label: "Data di nascita",
			size: "col-6",
		},
		{
			value: playerData?.placeOfBirth,
			label: "Luogo di nascita",
			size: "col-6",
		},
		{
			value: playerData?.address,
			label: "Indirizzo",
			size: "col-6",
		},
		{
			value: playerData?.nationality,
			label: "Nazionalità",
			size: "col-6",
		},
		{
			value: playerData?.email,
			label: "Email",
			size: "col-6",
		},
		{
			value: playerData?.telephone,
			label: "Telefono",
			size: "col-6",
		},
	];

	const teamConfiguration = [
		{
			value: playerData?.playerCategoryName,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
	];

	if (playerData?.goalkeeper) {
		teamConfiguration.push({
			value: playerData?.favoriteHand,
			label: "Mano",
			size: "col-6 col-lg-4",
		});
	} else {
		teamConfiguration.push({
			value: playerData?.primaryRole,
			label: "Ruolo",
			size: "col-6 col-lg-4",
		});
	}

	teamConfiguration.push(
		{
			value: playerData?.favoriteFoot,
			label: "Piede",
			size: "col-6 col-lg-4",
		},
		{
			value: playerData?.fatherHeight ? playerData?.fatherHeight + " cm" : null,
			label: "Altezza padre",
			size: "col-6 col-lg-4",
		},
		{
			value: playerData?.motherHeight ? playerData?.motherHeight + " cm" : null,
			label: "Altezza madre",
			size: "col-6 col-lg-4",
		},
	);

	if (user.roleName === "Admin") {
		teamConfiguration.push({
			value: playerData?.semaphore
				? playerSemaphoreOptions?.filter(
						(o) => o.value === playerData.semaphore,
					)?.[0]?.label
				: null,
			label: "Fascia",
			size: "col-6 col-lg-4",
		});
	}

	const physicalHeightColumns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.date).format(dateFormat);
			},
		},
		{
			label: "Altezza",
			key: "height",
			customRender: (item) => {
				return item.height + " cm";
			},
		},
	];
	const physicalWeightColumns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.date).format(dateFormat);
			},
		},
		{
			label: "Peso",
			key: "weight",
			customRender: (item) => {
				return item.weight + " kg";
			},
		},
	];
	const contactsColumns = [
		{label: "Nome", key: "name"},
		{label: "Email", key: "email"},
		{label: "Telefono", key: "telephone"},
		{label: "Parentela", key: "relation"},
	];
	const movementsColumns = [
		{
			label: "Stagione",
			key: "movementDate",
			align: "center",
			small: true,
			customRender: (item) => {
				const currentYear = moment(item.movementDate).year();
				const currentMonth = moment(item.movementDate).month() + 1;

				return currentMonth <= 6
					? "" + (currentYear - 1).toString() + "/" + currentYear
					: "" + currentYear + "/" + (currentYear + 1);
			},
		},
		{
			label: "Società",
			key: "prospectTeam?.name",
			align: "start",
			medium: true,
			customRender: (item) => item.prospectTeam?.name,
		},
		{
			label: "Tesseramento",
			key: "membershipDate",
			align: "center",
			medium: true,
			customRender: (item) => moment(item.membershipDate).format(dateFormat),
		},
		{
			label: "Status",
			key: "movementType?.status",
			align: "start",
			medium: true,
			customRender: (item) => item.movementType?.status,
		},
		{
			label: "Data",
			key: "movementDate",
			align: "center",
			medium: true,
			customRender: (item) => moment(item.movementDate).format(dateFormat),
		},
		{
			label: "Tipo",
			key: "movementType",
			align: "start",
			customRender: (item) => item.movementType?.description,
		},
	];

	const handleClose = () => {
		setOpenPhysicInfoWeight(false);
		setOpenPhysicHeightInfo(false);
		setOpenContact(false);
		setOpenMovement(false);
		// setOpenNote(false);
		setInitialData();
	};

	const handlePhysicInfo = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				playerId: playerData?.id,
				...data,
				height: !data.height ? "0" : data.height,
				date: moment(data.date).format("YYYY-MM-DD"),
			};
			id
				? await PlayerAPI.updateGrowth(playerData?.id, id, body)
				: await PlayerAPI.createGrowth(body);
			handleClose();
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deletePhysicInfo = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deleteGrowth(playerData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handleContact = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				playerId: playerData?.id,
				contactRole: data?.contactRole || "",
				...data,
			};
			id
				? await PlayerAPI.updateContact(playerData?.id, id, body)
				: await PlayerAPI.createContact(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deleteContact = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deleteContact(playerData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handleMovement = async (data, id) => {
		setLoading(true);

		try {
			let body = {
				id,
				movementDate: moment(data?.movementDate).format("YYYY-MM-DD"),
				playerId: playerData?.id,
				typeCode: data?.movement?.code,
				player: null,
				prospectTeamId: null,
				prospectTeam: null,
			};

			if (data?.movement?.updateStatus) {
				body = {...body, player: {...playerData, status: data?.status}};
			}

			if (data?.movement?.updateMembershipDate) {
				body = {
					...body,
					membershipDate: moment(data?.membershipDate).format("YYYY-MM-DD"),
				};
			}

			if (data?.movement?.defineAnotherTeam) {
				body.prospectTeamId = data?.prospectTeam?.id;
				body.prospectTeam = data?.prospectTeam;

				body = {
					...body,
					prospectTeamId: data?.prospectTeam?.id,
					prospectTeam: data?.prospectTeam,
				};
			}

			id
				? await PlayerAPI.updatePlayerMovement(id, body)
				: await PlayerAPI.createPlayerMovement(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayerMovements();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteMovement = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deletePlayerMovement(id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayerMovements();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleNote = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				...data,
				date: moment().format("YYYY-MM-DD"),
				playerId: playerData?.id,
			};
			id
				? await PlayerAPI.updateNote(playerData?.id, id, body)
				: await PlayerAPI.createNote(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deleteNote = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deleteNote(playerData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const breadcrumbsElements = [
		{
			label: "Giocatori",
			path: playerData?.goalkeeper ? "/players" : "/all-players",
		},
		{label: "/"},
	];

	const rpeValues = {};
	playerData?.playerRpe?.forEach((pr) => {
		if (!rpeValues[pr.rpe]) rpeValues[pr.rpe] = 0;
		rpeValues[pr.rpe] += 1;
	});

	const rpeChartData = [];
	Object.keys(rpeValues)?.forEach((rv) => {
		rpeChartData.push({
			name: rv,
			value: rpeValues[rv],
		});
	});

	const currentTab = useSelector((state) => state.playerTab);

	let mainActions = [];
	switch (currentTab) {
		case "main_info":
			mainActions =
				user?.userName === "CaputoD"
					? [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
						]
					: [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
							{
								icon: <EditIcon />,
								title: "Modifica",
								onClick: () => navigate("/players/" + playerData?.id + "/edit"),
							},
							{
								icon: <DeleteIcon />,
								title: "Elimina",
								onClick: () => setOpenHandleConfirm(true),
								color: "error",
							},
						];
			break;
		case "stats":
			mainActions = [
				{
					icon: <ArrowBackIcon />,
					title: "Indietro",
					onClick: () => navigate("/players"),
					color: "info",
				},
			];
			break;
		case "athletic_tests":
			mainActions =
				playerData?.status === "Interno"
					? [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
							{
								icon: <AddIcon />,
								title: "Aggiungi",
								onClick: () => navigate("/athletic-tests/new?playerId=" + id),
							},
						]
					: [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
						];
			break;
		case "valuations":
			mainActions =
				playerData?.status !== "Esterno"
					? [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
							{
								icon: <AddIcon />,
								title: "Aggiungi",
								onClick: () =>
									navigate("/players-valuations/new?playerId=" + id),
							},
						]
					: [
							{
								icon: <ArrowBackIcon />,
								title: "Indietro",
								onClick: () => navigate("/players"),
								color: "info",
							},
						];
			break;
		default:
			break;
	}

	const getChartFilters = (filter, setFilter, date, setDate) => {
		return (
			<div className="col-sm-12">
				<div className="stat-options">
					<div className="stat-switch">
						<div
							className={
								"single-option clickable" +
								(filter === "seasonal" ? " selected" : "")
							}
							onClick={() => {
								setFilter("seasonal");
							}}
						>
							Stagionale
						</div>
						<div
							className={
								"single-option clickable" +
								(filter === "monthly" ? " selected" : "")
							}
							onClick={() => {
								setFilter("monthly");
							}}
						>
							Mensile
						</div>
					</div>
					{filter === "monthly" ? (
						<div className="stat-input mx-5">
							<VLFDatePicker
								type="month"
								customDateFormat="MM/yyyy"
								value={date}
								onChange={setDate}
								required
								size="small"
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	};
	const getChartStats = (stats, fieldKey, filter) => {
		return (
			<div className="col-sm-12">
				<div className="stats-box-container mt-2">
					{stats.map((s, k) => {
						return (
							<div key={k} className="stat-box">
								<div className="stat-value">
									{Math.round(s[fieldKey] * 100) / 100}
								</div>
								<div className="stat-label">
									{filter === "monthly"
										? "Settimana " + (s.weekNumber || "")
										: s.year
											? moment()
													.set({
														month: s.month - 1,
														year: s.year,
													})
													.format("MMMM YYYY")
											: ""}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const [tqrStats, setTQRStats] = useState([]);
	const [rpeStats, setRPEStats] = useState([]);
	const [matchesStats, setMatchesStats] = useState({});
	const [playedMatches, setPlayedMatches] = useState([]);
	const [trainingsStats, setTrainingsStats] = useState([]);
	const [totalMatchesStats, setTotalMatchesStats] = useState({});

	const [tqrFilter, setTQRFilter] = useState("seasonal");
	const [tqrDate, setTQRDate] = useState(new Date());
	const [rpeFilter, setRPEFilter] = useState("seasonal");
	const [rpeDate, setRPEDate] = useState(new Date());
	const [matchesFilter, setMatchesFilter] = useState("seasonal");
	const [matchesDate, setMatchesDate] = useState(new Date());
	const [trainingsFilter, setTrainingsFilter] = useState("weekly");
	const [trainingsDate, setTrainingsDate] = useState(new Date());

	const getRPEStats = async () => {
		try {
			const response =
				rpeFilter === "monthly"
					? await StatAPI.weeklyRPE({
							playerId: id,
							month: moment(rpeDate).month() + 1,
							year: moment(rpeDate).year(),
						})
					: await StatAPI.monthlyRPE({playerId: id, seasonId});
			setRPEStats(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getTQRStats = async () => {
		try {
			const response =
				tqrFilter === "monthly"
					? await StatAPI.weeklyTQR({
							playerId: id,
							month: moment(tqrDate).month() + 1,
							year: moment(tqrDate).year(),
						})
					: await StatAPI.monthlyTQR({playerId: id, seasonId});
			setTQRStats(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getMatchesStats = async () => {
		try {
			const response =
				matchesFilter === "monthly"
					? await StatAPI.monthlyMatches({
							playerId: id,
							month: moment(matchesDate).month() + 1,
							year: moment(matchesDate).year(),
						})
					: await StatAPI.monthlyMatches({playerId: id, seasonId});

			const listResponse =
				matchesFilter === "monthly"
					? await StatAPI.matchesDetails(id, {
							month: moment(matchesDate).month() + 1,
							year: moment(matchesDate).year(),
						})
					: await StatAPI.matchesDetails(id, {seasonId});
			setPlayedMatches(listResponse);

			let stats = {};
			response.forEach((el) => {
				if (!stats[el.year + "-" + el.month])
					stats[el.year + "-" + el.month] = {};
				if (!stats[el.year + "-" + el.month][el.matchType])
					stats[el.year + "-" + el.month][el.matchType] = {};

				stats[el.year + "-" + el.month][el.matchType].goalsConceded =
					el.goalsConceded + "/" + el.totalGoalsConceded;
				stats[el.year + "-" + el.month][el.matchType].playedMinutes =
					el.totalMinutes + "/" + el.matchTotalMinutes;
				stats[el.year + "-" + el.month][el.matchType].matches =
					el.convocations + "/" + el.totalMatches;
			});

			const totalStats = {};
			matchTypes.forEach((mt) => {
				Object.keys(stats).forEach((sm) => {
					const current = stats[sm][mt];
					if (current) {
						const currentGoalsConceded = current.goalsConceded.split("/");
						const currentPlayedMinutes = current.playedMinutes.split("/");
						const currentMatches = current.matches.split("/");

						if (!totalStats[mt]) totalStats[mt] = {};
						totalStats[mt].goalsConceded =
							parseInt(totalStats[mt].goalsConceded || 0) +
							parseInt(currentGoalsConceded[0]);
						totalStats[mt].totalGoalsConceded =
							parseInt(totalStats[mt].totalGoalsConceded || 0) +
							parseInt(currentGoalsConceded[1]);

						totalStats[mt].playedMinutes =
							parseInt(totalStats[mt].playedMinutes || 0) +
							parseInt(currentPlayedMinutes[0]);
						totalStats[mt].totalPlayedMinutes =
							parseInt(totalStats[mt].totalPlayedMinutes || 0) +
							parseInt(currentPlayedMinutes[1]);

						totalStats[mt].convocations =
							parseInt(totalStats[mt].convocations || 0) +
							parseInt(currentMatches[0]);
						totalStats[mt].matches =
							parseInt(totalStats[mt].matches || 0) +
							parseInt(currentMatches[1]);
					}
				});
			});

			setTotalMatchesStats(totalStats);

			Object.keys(stats).forEach((mk) => {
				stats[mk] = Object.keys(stats[mk])
					.sort()
					.reduce((obj, key) => {
						obj[key] = stats[mk][key];
						return obj;
					}, {});
			});

			setMatchesStats(stats);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getTrainingsStats = async () => {
		try {
			const currentWeek = moment(trainingsDate).week();
			const response =
				trainingsFilter === "daily"
					? await StatAPI.trainingsDetails(id, {
							playerId: id,
							yearWeek:
								moment(trainingsDate).year() +
								"" +
								(currentWeek < 10 ? "0" + currentWeek : currentWeek),
						})
					: await StatAPI.weeklyTrainings(id, {
							playerId: id,
							month: moment(trainingsDate).month() + 1,
							year: moment(trainingsDate).year(),
						});
			setTrainingsStats(
				response
					.filter((r) => moment(r.trainingDate).year() < 2050)
					.map((r) => {
						if (r.trainingData) {
							r.startDate = r.trainingData.startDate;
						}
						return r;
					}),
			);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const [quickStats, setQuickStats] = useState({});
	const [detailedQuickStats, setDetailedQuickStats] = useState([]);
	const getQuickStats = async () => {
		try {
			const response = await PlayerAPI.getQuickStats(id);
			const detailedResponse = await PlayerAPI.getDetailedQuickStats(id);
			setQuickStats(response);
			setDetailedQuickStats(detailedResponse);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	useEffect(() => {
		if (currentTab === "stats") {
			getRPEStats();
		}
	}, [currentTab, rpeFilter, rpeDate]);
	useEffect(() => {
		if (currentTab === "stats") {
			getTQRStats();
		}
	}, [currentTab, tqrFilter, tqrDate]);
	useEffect(() => {
		if (currentTab === "stats") {
			getMatchesStats();
		}
	}, [currentTab, matchesFilter, matchesDate]);
	useEffect(() => {
		if (currentTab === "stats") {
			getTrainingsStats();
		}
	}, [currentTab, trainingsFilter, trainingsDate]);
	useEffect(() => {
		if (currentTab === "stats") {
			getQuickStats();
		}
	}, [currentTab]);
	useEffect(() => {
		if (
			currentTab &&
			tabs?.filter((t) => t.key === currentTab)?.[0].hidden &&
			Object.keys(playerData).length > 0
		) {
			dispatch(setPlayerTab("main_info"));
		}
	}, [currentTab, playerData]);

	const groupedTests = {};
	[{name: "Corse"}, {name: "Spinte"}].forEach((c) => {
		const currentType = c.name;
		const testsItems = playerData?.athleticTests
			?.filter((a) => a.testType === currentType)
			.map((a) => {
				a.playerId = id;
				return a;
			});

		groupedTests[currentType] = testsItems;
	});

	const listConfiguration = {
		data: groupedTests,
		onClick: (test) => navigate("/athletic-tests/" + test.testId),
	};

	const columns = [
		{
			label: "Data",
			key: "testDate",
			customRender: (item) => {
				return moment(item.testDate).format(dateFormat);
			},
			small: true,
		},
	];

	const runningColumns = [...columns];
	const jumpingColumns = [...columns];

	const runningData = playerData?.athleticTests?.filter(
		(t) => t.testType === "Corse",
	);
	const jumpingData = playerData?.athleticTests?.filter(
		(t) => t.testType === "Spinte",
	);

	runningData?.[runningData.length - 1]?.details?.forEach((td) => {
		runningColumns.push({
			label: td.valueType,
			key: "value",
			customRender: (item) => {
				const currentValue = item.details?.filter(
					(d) => d.valueType === td.valueType,
				)?.[0];
				return currentValue?.value || "---";
			},
			align: "end",
			small: true,
		});
	});
	jumpingData?.[jumpingData.length - 1]?.details?.forEach((td) => {
		jumpingColumns.push({
			label: td.valueType,
			key: "value",
			customRender: (item) => {
				const currentValue = item.details?.filter(
					(d) => d.valueType === td.valueType,
				)?.[0];
				return currentValue?.value || "---";
			},
			align: "end",
			small: true,
		});
	});

	const playedMatchesConfiguration = {
		data: playedMatches,
		onClick: (match) => navigate("/matches/" + match.matchId),
	};

	const playedMatchesColumns = [
		{
			label: "Data",
			key: "matchDate",
			customRender: (item) => {
				return moment(item.matchDate).format(dateFormat);
			},
			small: true,
		},
		{
			label: "Categoria",
			key: "playersCategory",
			medium: true,
			align: "start",
			customRender: (item) => {
				return (
					<div className={!item.categoryOfPlayer ? "highlighted-value" : ""}>
						{item.playersCategory}
					</div>
				);
			},
		},
		{
			label: "Partita",
			key: "match",
			align: "start",
			customRender: (item) => {
				return item.homeTeam + " - " + item.awayTeam;
			},
		},
		{
			label: "Risultato",
			key: "result",
			small: true,
			align: "start",
		},
		{
			label: "Tipologia",
			key: "matchType",
			medium: true,
			align: "start",
		},
		{
			label: "Convocato",
			key: "summoned",
			customRender: (item) => {
				return <div className={"cell" + (item.summoned ? " on" : " off")} />;
			},
			small: true,
		},
		{
			label: "Minuti giocati",
			key: "minutesPlayed",
			small: true,
			align: "end",
			customRender: (item) => {
				return item.minutesPlayed !== null && item.minutesPlayed !== undefined
					? item.minutesPlayed + "/" + item.matchMinutes
					: "---";
			},
		},
		{
			label: "Goal subiti",
			key: "goalsConceded",
			small: true,
			align: "end",
			customRender: (item) => {
				return item.goalsConceded !== null && item.goalsConceded !== undefined
					? item.goalsConceded + "/" + item.totalGoalsConceded
					: "---";
			},
		},
	];

	const trainingsDailyColumns = [
		{
			label: "Data",
			key: "trainingDate",
			customRender: (item) => {
				return moment(item.trainingDate).format(dateFormat);
			},
			small: true,
		},
		{
			label: "Ora",
			key: "startDate",
			customRender: (item) => {
				return moment(item.trainingData?.startDate).format("HH:mm");
			},
			small: true,
		},
		{
			label: "Categoria",
			key: "trainingData.playersCategory",
			small: true,
			align: "start",
		},
		{
			label: "Durata (min)",
			key: "trainingsDuration",
			small: true,
			align: "end",
		},
		{
			label: "RPE",
			key: "playerRpe",
			small: true,
			align: "end",
		},
		{
			label: "Training load",
			key: "trainingLoad",
			small: true,
			align: "end",
		},
	];
	const trainingsWeeklyColumns = [
		{
			label: "Da",
			key: "weekStartDate",
			customRender: (item) => {
				return moment(item.weekStartDate).format(dateFormat);
			},
			small: true,
		},
		{
			label: "A",
			key: "weekEndDate",
			customRender: (item) => {
				return moment(item.weekEndDate).format(dateFormat);
			},
			small: true,
		},
		{
			label: "Allenamenti",
			key: "totalTrainings",
			small: true,
			align: "end",
		},
		{
			label: "In altra categoria",
			key: "otherConvocations",
			small: true,
			align: "end",
		},
		{
			label: "In 1° squadra",
			key: "firstTeamConvocations",
			small: true,
			align: "end",
		},
		{
			label: "RPE minimo",
			key: "minRpe",
			small: true,
			align: "end",
		},
		{
			label: "RPE massimo",
			key: "maxRpe",
			small: true,
			align: "end",
		},
		{
			label: "RPE medio",
			key: "avgRpe",
			small: true,
			align: "end",
		},
		{
			label: "Training load",
			key: "trainingLoad",
			small: true,
			align: "end",
		},
	];

	const trainingsValues =
		trainingsFilter === "daily"
			? [
					{value: "trainingsDuration", label: "Durata (min.)"},
					{value: "playerRpe", label: "RPE"},
					{value: "trainingLoad", label: "Training load"},
				]
			: [
					{value: "totalTrainings", label: "Allenamenti"},
					{value: "otherConvocations", label: "In altra categoria"},
					{value: "firstTeamConvocations", label: "In 1° squadra"},
					{value: "minRpe", label: "RPE minimo"},
					{value: "maxRpe", label: "RPE massimo"},
					{value: "avgRpe", label: "RPE medio"},
					{value: "trainingLoad", label: "Training load"},
				];

	const careerConfiguration = {
		data: playerMovements,
		actions: [
			{
				icon: <EditIcon />,
				variant: "outlined",
				title: "Modifica",
				onClick: (item) => {
					setInitialData(Object.assign({}, item));
					setOpenMovement(true);
				},
			},
			{
				icon: <DeleteIcon />,
				variant: "outlined",
				title: "Elimina",
				onClick: (item) => {
					deleteMovement(item.id);
				},
				color: "error",
			},
		],
	};

	const getStatsGroups = (data) => {
		return [
			{
				title: "Generali",
				items: [
					{
						value: (data?.minutes || 0) + "'",
						label: "Minutaggio",
					},
				],
			},
			{
				title: "Tiri in porta",
				items: [
					{
						value: data?.saves || 0,
						label: "Parate",
					},
					{
						value: data?.goalsConceded || 0,
						label: "Gol subiti",
					},
				],
			},
			{
				title: "Letture",
				items: [
					{
						value: data?.outOfAreaReadings || 0,
						label: "Fuori area",
					},
					{
						value: data?.lowReadings || 0,
						label: "Area basse",
					},
					{
						value: data?.highReadings || 0,
						label: "Area alte",
					},
				],
			},
			{
				title: "Passaggi corti",
				items: [
					{
						value: data?.shortHorizontalPassRight || 0,
						label: "Orizz Dx",
					},
					{
						value: data?.shortHorizontalPassLeft || 0,
						label: "Orizz Sx",
					},
					{
						value: data?.shortVerticalPass || 0,
						label: "Verticale",
					},
				],
			},
			{
				// title: "Killer pass",
				items: [
					{
						value: data?.killerPass || 0,
						label: "Killer pass",
					},
				],
			},
			{
				title: "Passaggi lunghi",
				items: [
					{
						value: data?.longFrontalPass || 0,
						label: "Frontale",
					},
					{
						value: data?.longPassRight || 0,
						label: "Dx",
					},
					{
						value: data?.longPassLeft || 0,
						label: "Sx",
					},
				],
			},
		];
	};

	const getStatsRow = (stats) => {
		return getStatsGroups(stats).map((sg, k) => {
			return (
				<div
					key={k}
					className="stats-group"
					style={{
						flex: sg.items.length,
					}}
				>
					<div className="group-title">{sg.title}</div>
					<div className="group-items">
						{sg.items.map((sgi, k2) => {
							return (
								<div key={k2} className="player-stat">
									<div className="circle">
										<span className="number">{sgi.value}</span>
									</div>
									<div className="label">{sgi.label}</div>
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	};

	const tabs = [
		{
			key: "main_info",
			label: "Informazioni",
			render: () => {
				let lastHeight,
					lastWeight = 0;
				playerData?.playersGrowth?.forEach((pg) => {
					if (!lastHeight && pg.height) {
						lastHeight = pg.height;
					}

					if (!lastWeight && pg.weight) {
						lastWeight = pg.weight;
					}
				});

				const hasValuations = valuationsData?.length;

				return (
					<>
						<div className="row no-margin">
							<div className="col-sm-12 padding-small">
								<VLFCard
									title="Informazioni principali"
									content={
										<div className="row">
											<div
												className={
													hasValuations ? "col-12 col-xl-6" : "col-12 col-xl-4"
												}
											>
												<PlayerRadarChart
													playerData={playerData}
													valuationsData={valuationsData}
												/>
											</div>
											<div
												className={
													hasValuations ? "col-12 col-xl-6" : "col-12 col-xl-8"
												}
											>
												<ViewField configuration={mainConfiguration} />
											</div>
										</div>
									}
								/>
							</div>
						</div>
						<div className="row no-margin mt-2">
							<div className="col-sm-12 padding-small">
								<VLFCard
									title={
										<div className="d-flex align-items-center justify-content-between w-100">
											<div>Altre informazioni</div>
											{playerData?.status &&
												playerData?.status !== "Interno" && (
													<div className="player-status">
														{playerData?.status}
													</div>
												)}
										</div>
									}
									content={<ViewField configuration={teamConfiguration} />}
									openable={false}
								/>
							</div>
						</div>
						<div className="row no-margin mt-2 col-container">
							<div className="col-sm-12 padding-small">
								<div className="f-bold" style={{fontSize: "16px"}}>
									Dati antropometrici
								</div>
							</div>
							<div className="col-12 col-lg-6 padding-small">
								<CardsTable
									fullHeight={false}
									middleHeight
									configurationColumns={physicalHeightColumns}
									data={playerData?.playersGrowth?.filter(
										(elem) => elem.height,
									)}
									defaultSortedField="date"
									defaultSortedOrder="descend"
									showModes
									chartData={{
										type: "line",
										title: "Altezza",
										value: "height",
										label: "Altezza",
									}}
									actions={
										user?.userName !== "CaputoD"
											? [
													{
														icon: <ControlPointIcon />,
														onClick: () => setOpenPhysicHeightInfo(true),
													},
												]
											: []
									}
									rowActions={user?.userName !== "CaputoD"}
									handleEdit={(item) => {
										setInitialData(Object.assign({}, item));
										setOpenPhysicHeightInfo(true);
									}}
									handleDelete={(item) => {
										deletePhysicInfo(item.id);
									}}
								/>
							</div>
							<div className="col-12 col-lg-6 padding-small">
								<CardsTable
									fullHeight={false}
									middleHeight
									configurationColumns={physicalWeightColumns}
									data={playerData?.playersGrowth?.filter(
										(elem) => elem.weight,
									)}
									defaultSortedField="date"
									defaultSortedOrder="descend"
									showModes
									chartData={{
										type: "bar",
										title: "Peso",
										value: "weight",
										label: "Peso",
									}}
									actions={
										user?.userName !== "CaputoD"
											? [
													{
														icon: <ControlPointIcon />,
														onClick: () => setOpenPhysicInfoWeight(true),
													},
												]
											: []
									}
									rowActions={user?.userName !== "CaputoD"}
									handleEdit={(item) => {
										setInitialData(Object.assign({}, item));
										setOpenPhysicInfoWeight(true);
									}}
									handleDelete={(item) => {
										deletePhysicInfo(item.id);
									}}
								/>
							</div>
						</div>
						<div className="row no-margin mt-2">
							<div className="col-sm-12 padding-small">
								<CardsTable
									title="Contatti"
									fullHeight={false}
									middleHeight
									configurationColumns={contactsColumns}
									data={playerData?.playersContacts}
									actions={
										user?.userName !== "CaputoD"
											? [
													{
														icon: <ControlPointIcon />,
														onClick: () => setOpenContact(true),
													},
												]
											: []
									}
									rowActions={user?.userName !== "CaputoD"}
									handleEdit={(item) => {
										setInitialData(Object.assign({}, item));
										setOpenContact(true);
									}}
									handleDelete={(item) => {
										deleteContact(item.id);
									}}
								/>
							</div>
						</div>
						<div className="row no-margin mt-2">
							<div className="col-sm-12 padding-small">
								<CardsContainer
									tableComponent={
										<CardsTable
											title="Movimenti"
											fullHeight={false}
											middleHeight
											configurationColumns={movementsColumns}
											data={playerMovements}
											actions={
												user?.userName !== "CaputoD"
													? [
															{
																icon: <ControlPointIcon />,
																onClick: () => setOpenMovement(true),
															},
														]
													: []
											}
											rowActions={user?.userName !== "CaputoD"}
											handleEdit={(item) => {
												setInitialData(Object.assign({}, item));
												setOpenMovement(true);
											}}
											handleDelete={(item) => {
												deleteMovement(item.id);
											}}
											defaultSortedField="movementDate"
											defaultSortedOrder="descend"
										/>
									}
									listComponent={
										<CardsList
											type="player_movement"
											listConfiguration={careerConfiguration}
											defaultSortedField="movementDate"
											defaultSortedOrder="descend"
										/>
									}
								/>
							</div>
						</div>
						<NotesSection
							type="player"
							notes={playerData?.playersNotes}
							handleConfirm={handleNote}
							handleDelete={deleteNote}
						/>
						{user?.userName !== "CaputoD" && (
							<div className="row no-margin mt-2">
								<div className="col-sm-12 padding-small mt-2">
									<VLFCard
										title="Allegati"
										content={
											<AttachmentsContainer
												entityId={id}
												entityType="player"
												attachments={attachments}
												handleAdd={() => getPlayerAttachment()}
												handleDownload={getAttachment}
												handleDelete={(id) => {
													setIdAttachment(id);
													setOpenHandleAttachmentConfirm(true);
												}}
												handleNotification={handleNotification}
											/>
										}
									/>
									<VLFModal
										open={openHandleAttachmentConfirm}
										title="Eliminazione"
										content={
											<div className="mt-2">
												Sei sicuro di voler eliminare questo elemento?
											</div>
										}
										confirm={() => deleteAttachment()}
										close={() => setOpenHandleAttachmentConfirm(false)}
										confirmLabel="Conferma"
									/>
								</div>
							</div>
						)}
						{openPhysicHeightInfo ? (
							<HandlePhysicHeightInfo
								open={openPhysicHeightInfo}
								confirm={handlePhysicInfo}
								close={handleClose}
								initialData={initialData}
							/>
						) : null}
						{openPhysicInfoWeight ? (
							<HandlePhysicWeightInfo
								open={openPhysicInfoWeight}
								confirm={handlePhysicInfo}
								close={handleClose}
								initialData={initialData}
							/>
						) : null}
						{openContact ? (
							<HandleContactModal
								open={openContact}
								confirm={handleContact}
								close={handleClose}
								initialData={initialData}
							/>
						) : null}
						{openMovement ? (
							<HandleMovementModal
								open={openMovement}
								confirm={handleMovement}
								close={handleClose}
								initialData={initialData}
							/>
						) : null}
						<VLFModal
							open={openHandleConfirm}
							title="Eliminazione"
							content={
								<div className="mt-2">
									Sei sicuro di voler eliminare questo elemento?
								</div>
							}
							confirm={() => deletePlayer()}
							close={() => setOpenHandleConfirm(false)}
							confirmLabel="Conferma"
						/>
					</>
				);
			},
		},
		{
			key: "stats",
			label: "Statistiche",
			hidden: user?.userName === "CaputoD" || !playerData?.goalkeeper,
			render: () => {
				return (
					<div className="row no-margin col-container">
						{playerData?.hasStats ? (
							<div className="col-12 padding-small">
								<VLFCard
									title="Informazioni principali"
									content={
										<>
											<div className="match-player-stats">
												<div className="match-stats">
													{getStatsRow(quickStats)}
												</div>
											</div>
											<div className="match-player-stats">
												<div className="match-stats-details-container">
													{detailedQuickStats
														?.sort((a, b) => {
															const dateA = new Date(a.match.startDate);
															const dateB = new Date(b.match.startDate);
															return dateB - dateA;
														})
														?.map((dqs, k) => {
															return (
																<div
																	key={k}
																	className="d-flex flex-column w-100"
																>
																	<div className="mx-2 f-bold">
																		{moment(dqs.match.startDate).format(
																			"DD/MM/YYYY",
																		) +
																			" " +
																			dqs.match?.homeTeam +
																			" - " +
																			dqs.match?.awayTeam +
																			" " +
																			(dqs.match.results
																				? dqs.match.results
																				: "---")}
																	</div>
																	<div className="match-stats detailed">
																		{getStatsRow(dqs)}
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										</>
									}
								/>
							</div>
						) : null}
						{playerData?.hasStats ? (
							<div className="col-12 padding-small mt-2">
								<VLFCard
									title="Recuperi"
									content={
										<div className="row">
											{getChartFilters(
												tqrFilter,
												setTQRFilter,
												tqrDate,
												setTQRDate,
											)}
											{getChartStats(tqrStats, "avgTqr", tqrFilter)}
											<div className="col-sm-12 mt-2">
												<ChartContainer
													type="line"
													chartItems={[
														{
															value: "minTqr",
															label: "Minimo",
															color: "#007BFF",
														},
														{
															value: "avgTqr",
															label: "Medio",
															color: "#FFA500",
														},
														{
															value: "maxTqr",
															label: "Massimo",
															color: "#28A745",
														},
													]}
													data={tqrStats.map((ts) => {
														const date = moment().set({
															year: ts.year,
															month: ts.month - 1,
														});
														ts.chartLabel =
															tqrFilter === "monthly"
																? "Settimana " + ts.weekNumber
																: date.format("MMMM YYYY");
														return ts;
													})}
													dataKey="chartLabel"
												/>
											</div>
										</div>
									}
								/>
							</div>
						) : null}
						{playerData?.hasStats ? (
							<div className="col-12 padding-small mt-2">
								<VLFCard
									title="RPE"
									content={
										<div className="row">
											{getChartFilters(
												rpeFilter,
												setRPEFilter,
												rpeDate,
												setRPEDate,
											)}
											{getChartStats(rpeStats, "avgRpe", rpeFilter)}
											<div className="col-sm-12 mt-2">
												<ChartContainer
													type="line"
													chartItems={[
														{
															value: "minRpe",
															label: "Minimo",
															color: "#007BFF",
														},
														{
															value: "avgRpe",
															label: "Medio",
															color: "#FFA500",
														},
														{
															value: "maxRpe",
															label: "Massimo",
															color: "#28A745",
														},
													]}
													data={rpeStats.map((rs) => {
														const date = moment().set({
															year: rs.year,
															month: rs.month - 1,
														});
														rs.chartLabel =
															rpeFilter === "monthly"
																? "Settimana " + rs.weekNumber
																: date.format("MMMM YYYY");
														return rs;
													})}
													dataKey="chartLabel"
												/>
											</div>
										</div>
									}
								/>
							</div>
						) : null}
						<div className="col-12 padding-small mt-2">
							<VLFCard
								title="Partite"
								content={
									<div className="row">
										{getChartFilters(
											matchesFilter,
											setMatchesFilter,
											matchesDate,
											setMatchesDate,
										)}
										<CardsContainer
											tableComponent={
												<CardsTable
													configurationColumns={playedMatchesColumns}
													data={playedMatches}
													fullWidth
													fullHeight={false}
													middleHeight
													handleClick={(item) => {
														navigate("/matches/" + item.matchId);
													}}
													defaultSortedField="matchDate"
													defaultSortedOrder="descend"
												/>
											}
											listComponent={
												<CardsList
													type="match_player"
													middleHeight
													listConfiguration={playedMatchesConfiguration}
													defaultSortedField="matchDate"
													defaultSortedOrder="descend"
												/>
											}
										/>
										{matchesFilter !== "monthly" &&
											Object.keys(matchesStats)?.map((mk, k) => {
												const fullWidthBox = matchesFilter === "monthly";
												const month = mk.split("-")[1] - 1;
												const year = mk.split("-")[0];

												return (
													<div
														className={
															fullWidthBox
																? "col-12 padding-small mt-3"
																: "col-12 col-xl-6 padding-small mt-3"
														}
														key={k}
													>
														<div className={"month-matches-container"} key={k}>
															<div className="month-name">
																{fullWidthBox
																	? "Totale"
																	: moment()
																			.set({month, year})
																			.format("MMMM YYYY")}
															</div>
															<div className={"month-values-container " + mk}>
																{Object.keys(matchesStats[mk]).map(
																	(mkt, k2) => {
																		return (
																			<div
																				className="match-type-container"
																				key={k2}
																			>
																				<div className="match-type">{mkt}</div>
																				{Object.keys(matchesStats[mk][mkt]).map(
																					(mkts, k3) => {
																						return (
																							<div
																								className="type-stats-container"
																								key={k3}
																							>
																								<div className="type-stat-value">
																									{matchesStats[mk][mkt][mkts]}
																								</div>
																								<div className="type-stat-label">
																									{MATCH_STATS[mkts]}
																								</div>
																							</div>
																						);
																					},
																				)}
																			</div>
																		);
																	},
																)}
															</div>
														</div>
													</div>
												);
											})}
										{Object.keys(matchesStats)?.length ? (
											<div className="col-12 padding-small mt-2">
												<div className="month-matches-container">
													<div className="month-name">Totale</div>
													<div className="month-values-container all">
														{Object.keys(totalMatchesStats)?.map((ts, k) => {
															const current = totalMatchesStats[ts];
															return (
																<div key={k} className="match-type-container">
																	<div className="match-type">{ts}</div>
																	<div className="type-stats-container">
																		<div className="type-stat-value">
																			{current.goalsConceded +
																				"/" +
																				current.totalGoalsConceded}
																		</div>
																		<div className="type-stat-label">
																			Goal subiti
																		</div>
																	</div>
																	<div className="type-stats-container">
																		<div className="type-stat-value">
																			{current.playedMinutes +
																				"/" +
																				current.totalPlayedMinutes}
																		</div>
																		<div className="type-stat-label">
																			Minuti
																		</div>
																	</div>
																	<div className="type-stats-container">
																		<div className="type-stat-value">
																			{current.convocations +
																				"/" +
																				current.matches}
																		</div>
																		<div className="type-stat-label">
																			Partite
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										) : null}
										{!Object.keys(matchesStats)?.length && <EmptyData />}
									</div>
								}
							/>
						</div>
						{playerData?.hasStats ? (
							<div className="col-12 padding-small mt-2">
								<VLFCard
									title="Allenamenti"
									content={
										<div className="row trainings-stats-row">
											<div className="col-sm-12">
												<div className="stat-options">
													<div className="stat-switch">
														<div
															className={
																"single-option clickable" +
																(trainingsFilter === "weekly"
																	? " selected"
																	: "")
															}
															onClick={() => {
																setTrainingsFilter("weekly");
															}}
														>
															Mese - settimana
														</div>
														<div
															className={
																"single-option clickable" +
																(trainingsFilter === "daily" ? " selected" : "")
															}
															onClick={() => {
																setTrainingsFilter("daily");
															}}
														>
															Settimana - giorno
														</div>
													</div>
													{trainingsFilter === "daily" && (
														<div className="stat-input mx-5">
															<VLFDatePicker
																type="week"
																customDateFormat="w/yyyy"
																value={trainingsDate}
																onChange={setTrainingsDate}
																required
																size="small"
															/>
														</div>
													)}
													{trainingsFilter === "weekly" && (
														<div className="stat-input mx-5">
															<VLFDatePicker
																type="month"
																customDateFormat="MM/yyyy"
																value={trainingsDate}
																onChange={setTrainingsDate}
																required
																size="small"
															/>
														</div>
													)}
												</div>
											</div>
											<CardsTable
												fullHeight={false}
												fullWidth
												configurationColumns={
													trainingsFilter === "daily"
														? trainingsDailyColumns
														: trainingsWeeklyColumns
												}
												data={trainingsStats}
												defaultSortedField={
													trainingsFilter === "daily"
														? "startDate"
														: "weekStartDate"
												}
												defaultSortedOrder="descend"
											/>
											<div className="col-12">
												<div className="trainings-stats-container">
													{trainingsStats.map((ts, k) => {
														return (
															<div key={k} className="table-element">
																<div className="element-main-info">
																	<div>
																		{trainingsFilter === "daily"
																			? moment(ts.trainingDate).format(
																					dateFormat,
																				) +
																				" " +
																				moment(
																					ts.trainingData?.startDate,
																				).format("HH:mm")
																			: moment(ts.weekStartDate).format(
																					dateFormat,
																				) +
																				" - " +
																				moment(ts.weekEndDate).format(
																					dateFormat,
																				)}
																	</div>
																</div>
																<div className="d-flex flex-column w-100">
																	{trainingsFilter === "daily" ? (
																		<div
																			className="d-flex align-items-center justify-content-end px-2"
																			style={{fontSize: "12px"}}
																		>
																			{ts.trainingData?.playersCategory}
																		</div>
																	) : (
																		""
																	)}
																	<div className="element-info-container d-flex align-items-center flex-wrap ">
																		{trainingsValues.map((tv, kv) => {
																			return (
																				<div
																					key={kv}
																					className="info-container d-flex flex-column align-items-center"
																				>
																					<div className="value">
																						{ts[tv.value]}
																					</div>
																					<div className="label">
																						{tv.label}
																					</div>
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
														);
													})}
													{!trainingsStats.length && <EmptyData />}
												</div>
											</div>
										</div>
									}
								/>
							</div>
						) : null}
					</div>
				);
			},
		},
		{
			key: "athletic_tests",
			label: "Test atletici",
			hidden: user?.userName === "CaputoD" || !playerData?.goalkeeper,
			render: () => {
				return (
					<CardsContainer
						tableComponent={
							<div className="d-flex flex-column gap-2">
								<CardsTable
									title="Corse"
									size="sm"
									configurationColumns={runningColumns.sort((a, b) => {
										const numA = parseInt(a.label);
										const numB = parseInt(b.label);
										return numA - numB;
									})}
									data={runningData}
									handleClick={(test) =>
										navigate("/athletic-tests/" + test.testId)
									}
									fullWidth
									fullHeight={false}
									defaultSortedField={"testDate"}
									defaultSortedOrder="descend"
								/>
								<CardsTable
									title="Spinte"
									size="sm"
									configurationColumns={jumpingColumns}
									data={jumpingData}
									handleClick={(test) =>
										navigate("/athletic-tests/" + test.testId)
									}
									fullWidth
									fullHeight={false}
									defaultSortedField={"testDate"}
									defaultSortedOrder="descend"
								/>
							</div>
						}
						listComponent={
							<CardsList
								type="test_base"
								breadcrumbs
								grouped
								tabbed
								listConfiguration={listConfiguration}
								defaultGroupSortedOrder="ascend"
								defaultSortedField={"testDate"}
								defaultSortedOrder="descend"
							/>
						}
					/>
				);
			},
		},
		{
			key: "valuations",
			label: "Valutazioni",
			hidden: user?.userName === "CaputoD" || !playerData?.goalkeeper,
			render: () => {
				return (
					<>
						<div
							className="px-1"
							style={{fontSize: "16px", fontWeight: "bold"}}
						>
							Valutazioni generali
						</div>
						<ValuationsPage playerId={id} />
						<div
							className="px-1 mt-3"
							style={{fontSize: "16px", fontWeight: "bold"}}
						>
							Report gara
						</div>
						<MatchReportsPage playerId={id} />
						<NotesSection type="player_match" notes={playerNotes} />
					</>
				);
			},
		},
		{
			key: "medical",
			label: "Passaporto medico",
			hidden: user?.userName === "CaputoD" || !playerData?.goalkeeper,
			render: () => {
				return (
					<MedicalRecordPage
						onlyDetails
						handleNotification={handleNotification}
					/>
				);
			},
		},
		{
			key: "gps",
			label: "GPS",
			hidden:
				user?.userName === "CaputoD" ||
				!playerData?.goalkeeper ||
				!playerData?.hasStats,
			render: () => {
				return (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small">
							<GPSContainer
								handleNotification={handleNotification}
								gpsData={playerGPSData}
								playerId={id}
								callback={getPlayerGPS}
							/>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					playerData
						? (playerData.firstName || "") + " " + (playerData.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<Tabs
				tabs={tabs}
				currentTab={currentTab}
				setCurrentTab={(tab) => dispatch(setPlayerTab(tab))}
			/>
		</Page>
	);
}

export default PlayerPage;
