import * as React from "react";
import {useSelector} from "react-redux";
import moment from "moment";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function TrainingCard(props) {
	const {element} = props;

	const userRole = useSelector((state) => state?.user?.roleName);

	const hasRPE =
		parseInt(element?.playersCategory?.split(" ")?.[1]) > 13 ||
		element.playersCategory === "Prima Squadra";

	const presents = element.trainingConvocations?.filter(
		(c) => c.status === "Presente",
	);

	return (
		<div className="training-card-container">
			<div className="training-card-header">
				<div className="top-left">
					{hasRPE &&
					presents?.length &&
					presents.length > element.trainingRPE?.length ? (
						<div className="alert-container d-flex align-items-center gap-1">
							<ErrorOutlineIcon fontSize="small" />
							<span>RPE</span>
						</div>
					) : null}
				</div>
				<div className="top-right">{element.playersCategory}</div>
			</div>
			<div className="training-card-info">
				<div className="info-title">
					{moment(element.startDate).format(dateFormat)}
				</div>
				<div className="info-subtitle">
					{moment(element.startDate).format("HH:mm") +
						" - " +
						moment(element.endDate).format("HH:mm")}
				</div>
			</div>
			<div className="separator"></div>
			<div className="training-card-preview">
				<div className="training-type">
					<img src={require("@/assets/images/coach/training.png")} alt="" />
					<div className="type-info">{element.fieldName}</div>
				</div>
				{userRole !== "Player" ? (
					<div className="training-stats">
						<div className="single-stat">
							<img src={require("@/assets/images/manager/player.png")} alt="" />
							<div>{element.trainingConvocations?.length || 0}</div>
						</div>
						{hasRPE && (
							<div className="single-stat">
								<img src={require("@/assets/images/coach/rpe.png")} alt="" />
								<div>{element.trainingRPE?.length || 0}</div>
							</div>
						)}
					</div>
				) : hasRPE ? (
					<div className="training-stats">
						<div className="single-stat">
							<div>RPE</div>
							<div
								className={
									"value" +
									(element.trainingRPE?.length ? " inserted" : " empty")
								}
							></div>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}
