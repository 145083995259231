import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";
import HandleRosterModal from "./Modals/handleRoster";
import EmptyData from "@/components/EmptyData";

import {RosterAPI} from "@/api/roster";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";

import "./index.scss";

function RostersPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [rosters, setRosters] = useState([]);

	const [initialData, setInitialData] = useState();

	const getRosters = async () => {
		setLoading(true);
		try {
			const response = await RosterAPI.list();
			setRosters(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getRosters();
	}, []);

	const getPlayersListConfiguration = (roster) => {
		return {
			data: roster.players,
			onClick: (player) =>
				player.player
					? navigate("/players/" + player.playerId)
					: navigate("/scout-players/" + player.prospectPlayerId),
			separator: false,
		};
	};

	const handleRoster = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				...data,
				players: data.players?.map((p) => {
					return {
						rosterId: id,
						prospectPlayerId: p.internal ? undefined : p.id,
						playerId: p.internal ? p.id : undefined,
						playersCategoryId: data?.category?.value,
					};
				}),
			};

			// in edit, cancello il roster e ne ricreo uno nuovo
			if (id) {
				await deleteRoster(id);
			}
			await RosterAPI.create(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getRosters();
			setOpenModal(false);
			setInitialData(undefined);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deleteRoster = async (id) => {
		setLoading(true);
		try {
			await RosterAPI.delete(id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getRosters();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const mainActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi portiere",
			onClick: () => setOpenModal(true),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<div className="d-flex flex-column gap-2">
				{rosters.length ? (
					rosters
						.sort((a, b) => {
							const categoryA = a.players[0]?.playersCategory?.name || "";
							const categoryB = b.players[0]?.playersCategory?.name || "";

							if (categoryA > categoryB) return -1;
							if (categoryA < categoryB) return 1;
							return 0;
						})
						.map((r, k) => {
							return (
								<div className="rosters-container d-flex flex-column" key={k}>
									<div className="d-flex align-items-center justify-content-between">
										<div className="roster-title">
											{r.name +
												(r.players?.length
													? " - " + r.players[0]?.playersCategory?.name
													: "")}
										</div>
										<div className="d-flex align-items-center gap-3">
											<div
												className="d-flex align-items-center clickable"
												onClick={() => {
													setInitialData(Object.assign({}, r));
													setOpenModal(true);
												}}
											>
												<EditIcon className="roster-icon" />
											</div>
											<div
												className="d-flex align-items-center clickable"
												onClick={() => {
													deleteRoster(r.id);
												}}
											>
												<DeleteIcon className="roster-icon" />
											</div>
										</div>
									</div>
									<CardsList
										type="roster_player"
										listConfiguration={getPlayersListConfiguration(r)}
									/>
								</div>
							);
						})
				) : (
					<EmptyData />
				)}
			</div>
			{openModal ? (
				<HandleRosterModal
					open={openModal}
					confirm={handleRoster}
					close={() => {
						setOpenModal(false);
						setInitialData(undefined);
					}}
					initialData={initialData}
				/>
			) : null}
		</Page>
	);
}

export default RostersPage;
